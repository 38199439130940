import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from "react-i18next";

const ErrorDisplay = (err) => {
    const { t } = useTranslation()

    let message = ""
    const error = err.error
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // Response data should specify an error code
        const errorCode = error.response.data["errorCode"] || 0
        // Use the errorCode to look up an error message in the translations file
        message = t(`errors.${errorCode}`)
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // Display the default error message
        message = t(`errors.0`)
    } else {
        // Something happened in setting up the request that triggered an Error
        // Display the default error message
        message = t(`errors.0`)
    }
    return (
        <div><Alert severity="error" >
            <AlertTitle>{t(`error`)}</AlertTitle>
            {message}
        </Alert></div>
    )
}

export default ErrorDisplay;