import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// Import custom components
import TextField from './components/FormUI/TextField'
import SubmitButton from './components/FormUI/SubmitButton'
import Header from './components/Header'
import ImageUploader from './components/FormUI/ImageUploader'
import SuccessDisplay from './components/SuccessDisplay'
import ErrorDisplay from './components/ErrorDisplay'

import { SubmitClaim } from './BreezeCardAPI'
import { theme } from './theme'
import i18next from 'i18next';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submittedSuccessfully: false,
            submissionError: null,
            cardImage: '',
        }
    }

    handleSubmission = (values, formikHelpers) => {
        if (!values['photo']) {
            formikHelpers.setFieldError("photo", 'Required')
            this.setState({loading: false})
            return
        }
        this.setState({loading: true, submissionError: null})
        SubmitClaim(
            values['purchaseCode'], 
            values['name'], 
            values['email'],
            values['photo'],
            i18next.language,
        ).then(result => {
            this.setState({loading: false})
            if (result.data.purchaseCodeValid !== true) {
                formikHelpers.setFieldError("purchaseCode", "Invalid Purchase Code")
            } else {
                this.setState({
                    submittedSuccessfully: true, 
                    cardImage: result.data.cardImage,
                    submissionError: null
                })
            }
        }).catch(err => {
            this.setState({loading: false, submissionError: err})
        })
    }

    getInitialFormState() {
        return {
            name: '',
            email: '',
            purchaseCode: '',
            photo: null,
        }
    }

    getFormValidationRules() {
        const { t } = this.props
        return Yup.object().shape({
            name: Yup.string()
                .required(t('claimForm.requiredField')),
            purchaseCode: Yup.string()
                .required(t('claimForm.requiredField')),
            email: Yup.string()
                .email(t('claimForm.fields.email.invalid'))
                .required(t('claimForm.requiredField')),
            photo: Yup.mixed()
        });
    }
    
    setLanguage(locale) {
        if (typeof(locale) != "string") return
        const isEnglish = locale.includes("en")
        if (isEnglish) {
            i18next.changeLanguage("en")
        }
        else {
            i18next.changeLanguage("es")
        }
    }

    render() {
        const { t } = this.props
        return (
            <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={12}>
                    <Header locale={i18next.language} setLanguage={this.setLanguage}/>
                </Grid>
                <Grid item xs={12}>
                    {this.state.submittedSuccessfully ? <SuccessDisplay cardImage={this.state.cardImage} />
                    :
                    <Container maxWidth="sm">
                        <Box sx={{marginTop: "1em"}}>
                            <Formik 
                                initialValues={{...this.getInitialFormState()}}
                                validationSchema={this.getFormValidationRules()}
                                onSubmit={this.handleSubmission}
                                validateOnChange={true}
                            >
                                { ({errors, setFieldValue}) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" textAlign={'center'} >{t("claimForm.heading")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="purchaseCode" label={t("claimForm.fields.purchaseCode.label")} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField name="name" label={t("claimForm.fields.name.label")}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField name="email" label={t("claimForm.fields.email.label")} />
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <ImageUploader label={t("claimForm.fields.photoZone.label")}
                                                setMemberPhotoFile={(file) => {setFieldValue("photo", file)}}
                                                memberPhotoFile={this.state.memberPhotoFile}
                                                error={errors["photo"]}
                                            />
                                        </Grid>
                                        {this.state.submissionError ? <Grid item xs={12}>
                                            <ErrorDisplay error={this.state.submissionError} />
                                        </Grid> : ''}
                                        <Grid item xs={12} style={{display: "flex"}}>
                                            <SubmitButton loading={this.state.loading}>
                                                {t("claimForm.submit")}
                                            </SubmitButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                    }
                </Grid>
            </Grid>
            </ThemeProvider>
        );
    }
};

export default withTranslation()(App);