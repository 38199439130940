import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const LanguageSelector = ({locale, setLanguage}) => {
    let language = ""
    if (typeof(locale) == "string") language = (locale.includes("en") ? "en" : "es")

    const handleLanguage= (event, newLanguage) => {
        setLanguage(newLanguage);
    }
    
    return (
        <ToggleButtonGroup
            exclusive
            aria-label="language"
            value={language}
            onChange={handleLanguage}
            color="primary"
        >
            <ToggleButton value="en" aria-label="english" style={{"color": "white"}}>
                EN
            </ToggleButton>
            <ToggleButton value="es" aria-label="spanish" style={{"color": "white"}}>
                ES
            </ToggleButton>
        </ToggleButtonGroup>
    );
  };
  
  export default LanguageSelector;