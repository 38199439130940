import React from 'react';
import { Toolbar, AppBar, Typography, Box } from '@mui/material';
import logo from "../assets/logo.svg"

import LanguageSelector from './LanguageSelector';

const Header = ({locale, setLanguage}) => {
  return (
    <AppBar position="sticky" style={{height: "5em", backgroundColor: "black"}}>
      <Toolbar>
        <Box sx={{height: "75%", marginTop: '0', marginBottom: '0', marginLeft: 'auto', marginRight: 'auto'}}>
          <a href="https://thebocasbreeze.com/">
            <img src={logo} edge="start" alt="The Bocas Breeze" height="100%" style={{borderRadius: '5px'}}/>
          </a>
        </Box>
        <Box sx={{position: 'absolute', right: 0, top: 0, marginTop: '1em', marginLeft: '1em', width: '6em'}}>
          <LanguageSelector locale={locale} setLanguage={setLanguage} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;