import React, {Component} from 'react'
import {DropzoneArea} from "mui-file-dropzone"
import { withTranslation } from 'react-i18next'
 
class ImageUploader extends Component{
  constructor(props) {
    super(props);
    this.state = {
        fileSubmitted: false
    }
}

  handleChange(files){
    if (this.props.setMemberPhotoFile) {
      this.props.setMemberPhotoFile(files[0])
      if (files.length > 0) this.setState({ fileSubmitted: true })
    }
  }
  render(){
    const { t, error } = this.props
    var styleOverride = {}
    var dropzoneText = t("claimForm.fields.photoZone.label")
    if (error && error === 'Required') {
      styleOverride = {color: 'red'}
      dropzoneText = t("claimForm.fields.photoZone.required")
    } else if (this.state.fileSubmitted === true) {
      dropzoneText = ""
    }
    return (
      <div style={styleOverride}>
      <DropzoneArea
        className="photo-dropzone"
        onChange={this.handleChange.bind(this)}
        filesLimit={1}
        dropzoneText={dropzoneText}
        acceptedFiles= {["image/jpeg", "image/png"]}
      />
      </div>
    )
  }
}
 
export default withTranslation()(ImageUploader);