import React from 'react';
import { Box, Paper, Button, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const SuccessDisplay = ({cardImage}) => {
  const { t } = useTranslation()
  const imageURI = `data:image/png;base64,` + cardImage
  return (
    <Box style={{margin: '1em'}}>
      <Paper elevation={5} style={
        {
          display: "flex", 
          padding: "1em", 
          width: "fit-content", 
          flexDirection: "column",
          maxWidth: "900px",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}>
        <Typography variant="h3" style={{textAlign: 'center'}}>
          {t("successPage.heading")}
        </Typography>
        <img 
          src={imageURI} 
          alt="Your new digital Breeze Card"
          style={{
            maxWidth: "80%", 
            maxHeight: "35em",
            marginLeft:"auto", 
            marginRight: "auto", 
            marginTop: "1em", 
            marginBottom: "1em", 
            borderRadius: '5px'
          }}
        />
        <Typography variant="h6" style={{marginBottom: "0.5em"}}>{t("successPage.subheading")}</Typography>
        <Typography variant="body1" style={{marginTop: "0.5em", marginBottom: "1em"}}>
          {t("successPage.details")}
        </Typography>
        <Typography variant="body1" style={{width: "fit-content", marginLeft: "auto", marginRight: "auto"}}>
          {t("successPage.perksHeading")}
        </Typography>
        <Button href="https://thebocasbreeze.com/breezecard/" target="_blank" rel="noopener" 
          color="primary" variant="contained" style={{width: "fit-content", marginLeft: "auto", marginRight: "auto"}}
        >
          {t("successPage.perksLink")}
        </Button>
        <Typography variant="body1" style={{marginTop: "1em", marginBottom: "1em"}}>
          {t("successPage.moreDetails")}
        </Typography>
      </Paper>
    </Box>
  );
};

export default SuccessDisplay;