import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useFormikContext } from 'formik';

const ButtonWrapper = ({
    children,
    ...props
}) => {
    const { submitForm } = useFormikContext();

    const handleSubmit = () => {
        submitForm();
    }

    return (
        <LoadingButton
            onClick={handleSubmit}
            loading={props.loading}
            size="large"
            endIcon={<SendIcon />}
            loadingPosition="end"
            // change to style below
            style={{ margin: 'auto' }}
            variant="contained"
        >
            {children}
        </LoadingButton>
    );
};

export default ButtonWrapper;