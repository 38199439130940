import axios from 'axios'

const api = axios.create({
    baseURL: `/api/`
  });

// SubmitClaim sends a request to claim a digital Breeze Card for a specific person using a unique purchase code
export function SubmitClaim (purchaseCode, name, email, photoFile, language) {
    const formData = new FormData()
    formData.append("purchaseCode", purchaseCode)
    formData.append("name", name)
    formData.append("email", email)
    formData.append("memberPhoto", photoFile)

    // Standardize language string on "es" for Spanish and "en" for English
    if (language.includes("es")) language = "es"
    else language = "en"
    formData.append("language", language)

    return api.post(`cards/claim`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}